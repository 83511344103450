import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import AnimatedBlob from '../../components/AnimatedBlob';
import Loader from '../../components/Loader';
import ShinyButton from '../../components/ShinyButton';
import './style.scss';
import PartnerCallout from '../../components/PartnerCallout';
import { Link, navigate } from 'gatsby';
import { throwSignupConversionEvent } from '../../scripts/utils';

const Pricing = () => {
    useEffect(() => {});

    return (
        <Layout>
            <Seo title="Pricing" />
            <div className="pricing-page-2022">
                <Container className="container-1">
                    <Row>
                        <Col className="centered-text">
                            <h1>GlassHive is only $49 a user</h1>
                            <p className="hero-subtext">
                                Yeah, you read that right and no, it's not a
                                typo.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-2">
                    <AnimatedBlob
                        cls="canvas-1"
                        fill="rgba(121, 91, 255, .25)"
                    />
                    <AnimatedBlob
                        cls="canvas-2"
                        fill="rgba(251, 96, 157, .25)"
                    />
                    <Row>
                        <Col className="three-fourth">
                            <div className="subscription-card">
                                <div className="header">
                                    <h3>GlassHive's Subscription</h3>
                                </div>
                                <div className="content">
                                    <div className="features">
                                        <ul>
                                            <li>CRM</li>
                                            <li>Email Marketing</li>
                                            <li>Sales Management</li>
                                            <li>Tasking</li>
                                            <li>Integrations</li>
                                        </ul>
                                        <ul>
                                            <li>Marketing Plans</li>
                                            <li>Collateral Builder</li>
                                            <li>Marketing & Sales Reports</li>
                                            <li>Journeys (Automations)</li>
                                            <li>Unlimited Contacts</li>
                                        </ul>
                                        <ul>
                                            <li>Landing Page Builder</li>
                                            <li>SMS</li>
                                            <li>
                                                Proposal Builder{' '}
                                                <span className="accent">
                                                    *
                                                </span>
                                            </li>
                                            <li>
                                                Calling{' '}
                                                <span className="accent">
                                                    *
                                                </span>
                                            </li>
                                            <li>
                                                Social Media Management{' '}
                                                <span className="accent">
                                                    *
                                                </span>
                                            </li>
                                            <li className="disclaimer">
                                                * = Coming soon
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="price-container">
                                        <div className="total">
                                            <span className="price">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="302.055"
                                                    height="138"
                                                    viewBox="0 0 302.055 138"
                                                >
                                                    <g
                                                        id="Group_12326"
                                                        data-name="Group 12326"
                                                        transform="translate(-477.004 -1015.733)"
                                                    >
                                                        <text
                                                            id="_15"
                                                            data-name="$49"
                                                            transform="translate(556.004 1117.733)"
                                                            fill="#7e5dff"
                                                            font-size="58.913"
                                                            font-family="Nunito-Regular, Nunito"
                                                        >
                                                            <tspan
                                                                x="-78"
                                                                y="0"
                                                                baseline-shift="33.663299560546875"
                                                            >
                                                                $
                                                            </tspan>
                                                            <tspan
                                                                y="0"
                                                                font-size="101"
                                                                font-family="Nunito-Black, Nunito"
                                                                font-weight="800"
                                                            >
                                                                49
                                                            </tspan>
                                                        </text>
                                                        <text
                                                            id="monthly_Per_user"
                                                            data-name="monthly
Per user"
                                                            transform="translate(665.059 1082.711)"
                                                            fill="#59486e"
                                                            font-size="30"
                                                            font-family="Nunito-Bold, Nunito"
                                                            font-weight="700"
                                                        >
                                                            <tspan x="0" y="0">
                                                                monthly
                                                            </tspan>
                                                            <tspan x="0" y="24">
                                                                per user
                                                            </tspan>
                                                        </text>
                                                        <line
                                                            id="Line_1"
                                                            data-name="Line 1"
                                                            y1="88.455"
                                                            x2="30.452"
                                                            transform="translate(634.613 1041.11)"
                                                            fill="none"
                                                            stroke="#7e5dff"
                                                            stroke-linecap="round"
                                                            stroke-width="4"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="button-container">
                                            <ShinyButton
                                                className="btn pink"
                                                onClick={() =>
                                                    throwSignupConversionEvent(
                                                        'https://app.glasshive.com/Account/Login?s=signup'
                                                    )
                                                }
                                            >
                                                Let's Get Started
                                            </ShinyButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col className="three-fourth">
                            <div className="white-glove-card">
                                <div className="header">
                                    <h3>Want us to handle it all for you?</h3>
                                </div>
                                <div className="content">
                                    <div className="description-container">
                                        <h4>
                                            Take Advantage of our White Glove
                                            Service
                                        </h4>
                                        <span className="content-description">
                                            We know getting started on any new
                                            program can be the most challenging
                                            part. Our White Glove Services are
                                            tailored to make your experience
                                            with GlassHive simple and easy to
                                            use.
                                        </span>
                                    </div>
                                    <div className="features">
                                        <ul>
                                            <li>
                                                An assigned GlassHive IT
                                                Marketing and Sales Expert
                                            </li>
                                            <li>
                                                Complete setup and configuration
                                                of GlassHive{' '}
                                            </li>
                                            <li>Help with integrations</li>
                                            <li>
                                                12 months of digital marketing
                                                created and scheduled for you
                                            </li>
                                            <li>
                                                GlassHive Product Training for
                                                all users in your account{' '}
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                Built out sales follow up and
                                                automation with Journeys
                                            </li>
                                            <li>
                                                Monthly Reporting delivered via
                                                email
                                            </li>
                                            <li>
                                                Activity analytics and insights
                                                consulting
                                            </li>
                                            <li>
                                                QBRs to ensure plan stays on
                                                track
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="price-container">
                                        <div className="total">
                                            <span className="price">
                                                <span className="number">
                                                    <sup>$</sup>499
                                                </span>
                                                /mo or{' '}
                                                <span className="number">
                                                    <sup>$</sup>5000
                                                </span>
                                                /yr
                                            </span>
                                        </div>
                                    </div>
                                    <div className="image-container">
                                        <img
                                            src={require('../../images/pricing/gold-wave-bg.png')}
                                            alt="gold wave background"
                                        />
                                    </div>
                                    <div className="button-container">
                                        <Link
                                            to="/white-glove-service"
                                            className="btn"
                                        >
                                            Learn More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col className="three-fourth comparison-card-col">
                            <div className="comparison-card">
                                <h3>
                                    You can save hundreds by switching to us
                                </h3>
                                <p>
                                    Wouldn't it be nice to consolidate all your
                                    tools in one unified WorkOS? GlassHive has
                                    all the tools your team needs and replaces
                                    the clutter of apps you're using today.
                                </p>
                                <div className="logos">
                                    <img
                                        src={require('../../images/pricing/hubspot.png')}
                                        alt="hubspot logo"
                                    />
                                    <img
                                        src={require('../../images/pricing/canva.png')}
                                        alt="canva logo"
                                    />
                                    <img
                                        src={require('../../images/pricing/mailchimp.png')}
                                        alt="mailchimp logo"
                                    />
                                    <img
                                        src={require('../../images/pricing/pipedrive.png')}
                                        alt="pipedrive logo"
                                    />
                                    <img
                                        src={require('../../images/pricing/hootsuite.png')}
                                        alt="hootsuite logo"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <PartnerCallout />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Pricing;
