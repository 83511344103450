import React from 'react';
import './style.scss';

const PartnerCallout = () => {
    return (
        <div className="partner-callout">
            <h3 className="white-text margin-auto">
                Over 12,000 users have joined the Hive. Here are some customers
                who've used our WorkOS and seen real results.
            </h3>
            <ul className="partners">
                <li>
                    <img
                        src={require('../../images/home/ingram.png')}
                        alt="ingram logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/home/trend-micro.png')}
                        alt="trend micro logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/home/757px-Apple_logo_white.png')}
                        alt="apple logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/microsoft.png')}
                        alt="microsoft logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/cisco.png')}
                        alt="cisco logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/citrix.png')}
                        alt="citrix logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/f5.png')}
                        alt="f5 logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/ibm.png')}
                        alt="ibm logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/fortinet.png')}
                        alt="Fortinet Logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/home/Lenovo_logo.png')}
                        alt="lenovo logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/brother.png')}
                        alt="brother logo"
                    />
                </li>
                <li>
                    <img
                        src={require('../../images/logos/rsa.png')}
                        alt="rsa logo"
                    />
                </li>
            </ul>
        </div>
    );
};

export default PartnerCallout;
